import Slider from 'components/common/Slider';

import HealthImage from 'images/abilities/alpaca-health.svg';
import ManageImage from 'images/abilities/alpaca-manage.svg';

import { alpacaLabels, items } from 'data/sliderItems';

const sliderInformation = (): JSX.Element => (
  <Slider
    slides={items.map(item => ({ ...item, labels: alpacaLabels }))}
    className='slider-information-animation'
    duration={1550}
  />
);

const abilities = [
  {
    type: 'protect',
    title: 'Know your alpaca',
    description: `From the beloved pet to the best in breed show\nwinner, knowing your alpaca history and recording\ntheir ancestry can lead to better welfare, realisation\nof value and improved care and intervention.\n
        The Alpaca Guild international registry is open to\nall alpacas and is leading the industry in\ntraceability and visualisation of alpaca pedigree.`,
    Image: ManageImage,
    button: false,
  },
  {
    type: 'welfare',
    title: 'Alpaca Management',
    description: `Bronze membership includes full alpaca management tools to\u00A0help you maintain healthy and happy alpacas.\n
    Stay on\u00A0top of\u00A0all the important health and wellbeing tasks of\u00A0alpaca ownership.\n
    Silver membership adds additional tools for breeders looking to\u00A0 boost their business and stand out amongst the herd.`,
    Image: HealthImage,
    button: true,
  },
  {
    type: 'gate',
    title: 'Beyond the farm gate',
    description: `We are a community of like minded members with a passion for improving the long term future of alpaca.\n
        Alpaca Guild is investing in a sustainable ecosystem of industry partners centred around alpacas that appreciates their value and recognises your participation.`,
    Image: sliderInformation,
    button: true,
  },
];

export default abilities;
