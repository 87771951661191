import { FC } from 'react';

import Section from 'components/common/Section';

import FirstCard from 'images/sell/first-card.svg';
import SecondCard from 'images/sell/second-card.svg';
import ThirdCard from 'images/sell/third-card.svg';
import FourthCard from 'images/sell/fourth-card.svg';

const AlpacaSell: FC = () => (
  <Section className='manager-alpaca-sell'>
    <div className='data'>
      <h2 className='title'>Alpaca Guild Marketplace</h2>
      <p className='description'>
        {`
           Buying and selling alpacas has never been easier.\n
           Silver membership includes a\u00A0free dynamicwebsite that automatically lists your alpacasand pulls data from the international registry.\n
           Simply choose the alpacas that you wish to\u00A0selland see your own website update without effort.\n
           Buyers can be\u00A0confident that the information iscoming from the trusted international registry.'
        `}
      </p>
    </div>

    <div className='animal-cards'>
      <div className='left-side'>
        <div className='animal first-card'>
          <FirstCard />
        </div>
        <div className='animal second-card'>
          <SecondCard />
        </div>
        <div className='animal first-card'>
          <FirstCard />
        </div>
        <div className='animal second-card'>
          <SecondCard />
        </div>
      </div>

      <div className='right-side'>
        <div className='animal third-card'>
          <ThirdCard />
        </div>
        <div className='animal fourth-card'>
          <FourthCard />
        </div>
        <div className='animal third-card'>
          <ThirdCard />
        </div>
        <div className='animal fourth-card'>
          <FourthCard />
        </div>
      </div>
    </div>
  </Section>
);

export default AlpacaSell;
