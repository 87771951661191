import { FC } from 'react';

import LogoIcon from '../../../static/icons/Logo.svg';

const Logo: FC = () => {
  return (
    <a className='logo' href='/' rel='noopener noreferrer'>
      <LogoIcon width={32} height={32} />
      <h5 className='logo-title'>Alpaca Guild</h5>
    </a>
  );
};

export default Logo;
