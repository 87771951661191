import { FC } from 'react';

import Section from 'components/common/Section';

import abilities from 'data/managerAbilities';

const Abilities: FC = () => (
  <Section className='manager-abilities'>
    <div className='abilities-items'>
      {abilities.map(({ title, description, Image, type }) => (
        <div className={`ability-item ${type}`} key={title}>
          <div className='data'>
            <h2 className='title'>{title}</h2>
            <p className='description'>{description}</p>
          </div>
          <div className='image'>
            <Image />
          </div>
        </div>
      ))}
    </div>
  </Section>
);

export default Abilities;
