import { FC, Fragment, useMemo } from 'react';

import Welcome from 'components/blocks/Welcome';
import Abilities from 'components/blocks/Abilities';
import CallToAction from 'components/blocks/CallToAction';
import AlpacaSell from 'components/blocks/AlpacaSell';
import Accessibility from 'components/blocks/Accessibility';
import Blog from 'components/blocks/blog/Blog';

import mapEdgesToNodes from 'helpers/mapEdgesToNodes';
import filterOutDocs from 'helpers/filterOutDocs';

const Content: FC<{ posts: any }> = ({ posts }) => {
  const postNodes = useMemo(() => {
    if (posts) return mapEdgesToNodes(posts).filter(filterOutDocs);

    return [];
  }, [posts]);

  return (
    <Fragment>
      <Welcome />
      <Abilities />
      <CallToAction
        className='custom'
        title='Become part of our community'
        description={
          'Join the global community of alpaca owners, breeders\nand industry professionals.'
        }
      />
      <Fragment>
        <div className='background--grey'>
          <Blog articles={postNodes} link='/blog' />
        </div>
      </Fragment>
      <AlpacaSell />
      <Accessibility />
      <CallToAction />
    </Fragment>
  );
};

export default Content;
