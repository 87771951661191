import { FC } from 'react';

import InstagramIcon from '../../../static/icons/social/instagram.svg';
import FacebookIcon from '../../../static/icons/social/facebook.svg';
import PinterestIcon from '../../../static/icons/social/pinterest.svg';
import SnapchatIcon from '../../../static/icons/social/snapchat.svg';
import TiktokIcon from '../../../static/icons/social/tiktok.svg';
import TwitterIcon from '../../../static/icons/social/twitter.svg';
import YoutubeIcon from '../../../static/icons/social/youtube.svg';

const links: { Icon: any; link: string }[] = [
  {
    Icon: YoutubeIcon,
    link: 'https://www.youtube.com/@alpacaguild',
  },
  {
    Icon: TwitterIcon,
    link: 'https://twitter.com/alpacaguild',
  },
  {
    Icon: FacebookIcon,
    link: 'https://www.facebook.com/alpacaguild',
  },
  { Icon: InstagramIcon, link: 'https://www.instagram.com/alpacaguild' },
  {
    Icon: PinterestIcon,
    link: 'https://www.pinterest.com/alpacaguild',
  },
  {
    Icon: TiktokIcon,
    link: 'https://www.tiktok.com/@alpacaguild',
  },
  {
    Icon: SnapchatIcon,
    link: 'https://www.snapchat.com/add/alpacaguild',
  },
];

const SocialLinks: FC = () => {
  return (
    <div className='social-links'>
      {links.map(({ link, Icon }, index) => (
        <a
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          href={link}
          target='_blank'
          rel='noopener noreferrer'
          className='social-link'
        >
          <Icon />
        </a>
      ))}
    </div>
  );
};

export default SocialLinks;
