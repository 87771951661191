import { FC } from 'react';

import { StaticQuery, graphql } from 'gatsby';

import { GatsbyImage, getImage, IGatsbyImageData } from 'gatsby-plugin-image';

interface Props {
  src: string;
  alt: string;
  className?: string;
}

const Image: FC<Props> = ({ src, alt, className }) => {
  const query = graphql`
    query {
      allImageSharp {
        edges {
          node {
            gatsbyImageData(placeholder: BLURRED)
            fluid(maxWidth: 1200, quality: 95) {
              ...GatsbyImageSharpFluid_tracedSVG
              originalName
            }
          }
        }
      }
    }
  `;

  return (
    <StaticQuery
      query={query}
      render={data => {
        const image = data.allImageSharp.edges.find(
          (edge: any) => edge.node.fluid.originalName === src,
        );

        if (!image) {
          throw new Error(`There is no image with such a name "${src}"`);
        }
        const imageFormatted = getImage(image.node) as IGatsbyImageData;

        return (
          <div>
            <GatsbyImage image={imageFormatted} alt={alt} className={className} />
          </div>
        );
      }}
    />
  );
};

export default Image;
