import { FC, useState } from 'react';

import ReactPlayer from 'react-player/file';

import Slider from 'components/common/Slider';
import MoreLinks from 'components/blocks/MoreLinks';
import Image from 'components/common/Image';
import SocialLinks from 'components/common/SocialLinks';
import Logo from 'components/common/Logo';

import { items, slideAnimation } from 'data/welcomeData';

const Welcome: FC = () => {
  const [onError, setError] = useState(false);
  const [onLoading, setLoading] = useState(true);

  return (
    <div className='welcome alpaca-guild'>
      <div className='left-side-section'>
        <div className='left-side-content'>
          <Logo />
          <div className='main-content'>
            <h2 className='title headline-title'>{'Become part\nof our community'}</h2>
            <p className='description'>
              {'Join the global community of alpaca owners,\n breeders and industry professionals.'}
            </p>
            <div className='discover-links'>
              <MoreLinks showDiscover />
            </div>
          </div>
          <SocialLinks />
        </div>
      </div>

      <div className='right-side-section'>
        <div className='side-container'>
          {onError ||
            (onLoading && (
              <Image
                className='gatsby-image-wrapper'
                src='alpaca-guild.jpg'
                alt='Alpaca Guild Image'
              />
            ))}

          <ReactPlayer
            className='video-item'
            playsinline
            playing
            muted
            loop
            url='https://dvfsxesbc88ri.cloudfront.net/videos/alpaca.mp4'
            height='100%'
            width='100%'
            onError={() => setError(true)}
            onProgress={({ loadedSeconds }: { loadedSeconds: number }) =>
              loadedSeconds > 0.01 && setLoading(false)
            }
            config={{
              file: {
                attributes: {
                  style: { objectFit: 'cover', height: '100%', width: '100%' },
                },
              },
            }}
          />
        </div>
      </div>

      <div className='container-for-slider alpaca-guild'>
        <Slider slides={items} animation={slideAnimation} />
      </div>
    </div>
  );
};

export default Welcome;
