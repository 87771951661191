import { FC } from 'react';

import LinkButton from 'components/common/LinkButton';

import app from 'helpers/app';

type Props = {
  showDiscover?: boolean;
};

const MoreLinks: FC<Props> = () => (
  <div className='buttons-group'>
    <LinkButton href={`${app.managerLink}/sign-up`}>Sign Up</LinkButton>

    <LinkButton className='log-in-button' href={`${app.managerLink}/sign-in`}>
      Log In
    </LinkButton>
  </div>
);

export default MoreLinks;
