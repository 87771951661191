import { FC, useEffect, useState, useRef, useCallback } from 'react';

import throttle from 'lodash/throttle';
import RenderSmoothImage from 'render-smooth-image-react';
import 'render-smooth-image-react/build/style.css';

import ArrowButton from 'images/icons/arrow-button.svg';

type AnimationProps = { transform?: string; opacity?: number; offset?: number };

interface Props {
  className?: string;
  slides: any;
  duration?: number;
  animation?: AnimationProps[] | null;
}

interface SlideLabel {
  key: string;
  slideDuration: number;
  fadeInDuration: number;
  startDelay: number;
}

const fadeOut = [{ opacity: 1 }, { opacity: 0.15 }];
const fadeIn = [{ opacity: 0.15 }, { opacity: 1 }];

const fadeInLabel = [{ opacity: 0 }, { opacity: 1 }];

const labelSlide = [
  { transform: 'translateX(0)', opacity: 1 },
  { transform: 'translateX(-100%)', opacity: 0 },
];

const labelSlideReverse = [
  { transform: 'translateX(0)', opacity: 1 },
  { transform: 'translateX(100%)', opacity: 0 },
];

const Slider: FC<Props> = ({ slides, duration = 3000, animation = null, className }) => {
  const starterSlide = 0;
  const [index, setIndex] = useState(starterSlide);
  const [slide, setSlide] = useState(slides[starterSlide]);

  const sliderRef = useRef<HTMLDivElement | null>(null);
  const labelsRef = useRef<HTMLDivElement | null>(null);

  const halfDuration = duration / 2;

  useEffect(() => {
    if (className !== 'slider-information-animation') {
      const interval = setInterval(() => {
        if (sliderRef?.current)
          sliderRef.current.animate(animation, {
            duration,
            iterations: Infinity,
            easing: 'linear',
          });

        if (index < slides.length - 1) setIndex(index + 1);
        else setIndex(starterSlide);
      }, duration);

      setSlide(slides[index]);

      return () => clearInterval(interval);
    }
  }, [animation, className, duration, index, slides]);

  useEffect(() => {
    if (className === 'slider-information-animation') {
      sliderRef?.current?.animate(fadeOut, {
        duration: halfDuration,
        easing: 'ease-in',
      });

      const timeout = setTimeout(() => {
        setSlide(slides[index]);
        sliderRef?.current?.animate(fadeIn, {
          duration: halfDuration,
          easing: 'ease-in',
        });
      }, halfDuration);

      return () => clearTimeout(timeout);
    }
  }, [className, halfDuration, index, slides]);

  const slideLabels = useCallback(() => {
    slide.labels.forEach(
      ({ key, slideDuration, fadeInDuration, startDelay }: SlideLabel, labelIndex: number) => {
        if (key !== 'Breed' && key !== 'Medication due') {
          setTimeout(() => {
            labelsRef.current?.children[labelIndex].animate(labelSlide, {
              duration: slideDuration,
              easing: 'ease-in',
            });
          }, startDelay);
        } else {
          setTimeout(() => {
            labelsRef.current?.children[labelIndex].animate(labelSlideReverse, {
              duration: slideDuration,
              easing: 'ease-in',
            });
          }, startDelay);
        }
        setTimeout(() => {
          labelsRef.current?.children[labelIndex].animate(fadeInLabel, {
            duration: fadeInDuration,
            easing: 'ease-in',
          });
        }, Number(slideDuration) + Number(startDelay));
      },
    );
  }, [slide.labels]);

  return className === 'slider-information-animation' ? (
    <div className='slider-information-slide'>
      <div className='card-feature'>
        <div className='item-container'>
          <div className='item'>
            <div className='labels-group' ref={labelsRef}>
              {slide.labels.map(({ key, value }: { key: string; value: string }) => (
                <div className={`label ${key.toLowerCase()}`} key={key}>
                  <span>{value}</span>
                </div>
              ))}
            </div>
            <div className='buttons-group'>
              <button
                type='button'
                className='btn btn-prev'
                onClick={throttle(() => {
                  if (index > starterSlide) setIndex(index - 1);
                  else setIndex(slides.length - 1);
                  slideLabels();
                }, 3000)}
              >
                <ArrowButton />
              </button>
              <button
                type='button'
                className='btn btn-next'
                onClick={throttle(() => {
                  if (index < slides.length - 1) setIndex(index + 1);
                  else setIndex(starterSlide);
                  slideLabels();
                }, 3000)}
              >
                <ArrowButton />
              </button>
            </div>
            <div className='slider-container' ref={sliderRef}>
              {/* <Image className='slider-image' src={slide.image} alt={slide.alt} /> */}

              <RenderSmoothImage className='slider-image' src={slide.image} alt={slide.alt} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className='slider'>
      <div className='card-feature'>
        <div className='item-container' ref={sliderRef}>
          <div className='item'>
            {slide.icon}
            <p>{slide.description}</p>
          </div>
          <span className={`badge ${slide.badge}`}>
            <span className={`badge-text ${slide.badge}`}>{slide.badge?.toUpperCase()}</span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default Slider;
