import { FC } from 'react';

import Section from 'components/common/Section';
import MoreLinks from 'components/blocks/MoreLinks';

type Props = {
  className?: string;
  title?: string;
  description?: string;
};

const CallToAction: FC<Props> = ({
  className,
  title = 'A Healthy Alpaca is a Happy Alpaca',
  description = 'Alpaca Guild. Putting you and your alpaca first.',
}) => (
  <Section className={`call-to-action ${className}`}>
    <h2 className='title'>{title}</h2>

    <p className='description'>{description}</p>

    <MoreLinks />
  </Section>
);

export default CallToAction;
