import FirstSlide from 'images/abilities/slider/alpaca-slider-first-image.jpg';
import SecondSlide from 'images/abilities/slider/alpaca-slider-second-image.jpg';
import ThirdSlide from 'images/abilities/slider/alpaca-slider-third-image.jpg';

import preloadImages from 'helpers/preloadImages';

preloadImages([FirstSlide, SecondSlide, ThirdSlide]);

export const items = [
  {
    image: FirstSlide,
    alt: 'alpaca first slider image',
    labels: [
      {
        key: 'Status',
        value: 'Active',
        slideDuration: 500,
        fadeInDuration: 500,
        startDelay: 100,
      },
      {
        key: 'Age',
        value: '2 year and 5 month old',
        slideDuration: 600,
        fadeInDuration: 400,
        startDelay: 0,
      },
      {
        key: 'Color',
        value: 'White',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 150,
      },
      {
        key: 'Medication due',
        value: 'Frontline - 2ml on Jan 31, 2000',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 200,
      },
    ],
  },
  {
    image: SecondSlide,
    alt: 'alpaca second slider image',
    labels: [
      {
        key: 'Status',
        value: 'Active',
        slideDuration: 500,
        fadeInDuration: 500,
        startDelay: 100,
      },
      {
        key: 'Age',
        value: '1 year and 12 month old',
        slideDuration: 600,
        fadeInDuration: 400,
        startDelay: 0,
      },
      {
        key: 'Color',
        value: 'Medium Brown',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 150,
      },
      {
        key: 'Medication due',
        value: 'A,D&E - 12ml, 13 Dec, 2021',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 200,
      },
    ],
  },
  {
    image: ThirdSlide,
    alt: 'alpaca third slider image',
    labels: [
      {
        key: 'Status',
        value: 'Sold',
        slideDuration: 500,
        fadeInDuration: 500,
        startDelay: 100,
      },
      {
        key: 'Age',
        value: '5 year and 6 month old',
        slideDuration: 600,
        fadeInDuration: 400,
        startDelay: 0,
      },
      {
        key: 'Color',
        value: 'Dark Brown',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 150,
      },
      {
        key: 'Medication due',
        value: 'Matrix - 34ml, 28 Oct, 2021',
        slideDuration: 600,
        fadeInDuration: 500,
        startDelay: 200,
      },
    ],
  },
];

export const alpacaLabels = [
  {
    key: 'Status',
    value: 'Proven genetics',
    slideDuration: 500,
    fadeInDuration: 500,
    startDelay: 100,
  },
  {
    key: 'Age',
    value: 'From fleece to fashion',
    slideDuration: 600,
    fadeInDuration: 400,
    startDelay: 0,
  },
  {
    key: 'Color',
    value: 'Services and partnerships',
    slideDuration: 600,
    fadeInDuration: 500,
    startDelay: 150,
  },
  {
    key: 'Medication due',
    value: 'Breeding and sales',
    slideDuration: 600,
    fadeInDuration: 500,
    startDelay: 50,
  },
  {
    key: 'Breed',
    value: 'Tourism and education',
    slideDuration: 600,
    fadeInDuration: 500,
    startDelay: 200,
  },
];
