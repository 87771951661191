import AlpacaIcon from 'images/icons/alpaca-icon.svg';
import AlpacaMed from 'images/icons/alpaca-med-slider.svg';
import AlpacaTree from 'images/icons/alpaca-tree-icon.svg';

export const slideAnimation = [
  { transform: 'translateX(238px)', opacity: 0, offset: 0.0 },
  { transform: 'translateX(-50px)', opacity: 1, offset: 0.1 },
  { transform: 'translateX(0)', offset: 0.15 },
  { transform: 'translateX(0)', offset: 0.85 },
  { transform: 'translateX(50px)', opacity: 1, offset: 0.95 },
  { transform: 'translateX(-238px)', opacity: 0, offset: 1.0 },
];

export const items = [
  { icon: <AlpacaIcon />, description: 'Participate in animal shows', badge: 'soon' },
  { icon: <AlpacaMed />, description: 'Never forget to inject the medicine', badge: 'new' },
  { icon: <AlpacaTree />, description: 'View animal family tree', badge: 'soon' },
];
