const preloadImages = (urlList: any): any => {
  if (typeof document === 'object') {
    const fragment = document.createDocumentFragment();

    urlList.forEach((url: any) => {
      const linkEl = document.createElement('link');

      linkEl.setAttribute('rel', 'preload');
      linkEl.setAttribute('href', url);
      linkEl.setAttribute('as', 'image');

      return fragment.appendChild(linkEl);
    });

    document.head.appendChild(fragment);
  }
};

export default preloadImages;
