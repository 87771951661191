import { FC } from 'react';

import Section from 'components/common/Section';
import Image from 'components/common/Image';

const Accessibility: FC = () => (
  <Section className='accessibility'>
    <div className='accessibility-section'>
      <div className='image-container tablet-image'>
        <Image src='accessibility-tablet-alpaca.png' alt='tablet image' />
      </div>
      <div className='data text--overlap'>
        <h2 className='title'>Easy. Accessible. Trusted.</h2>
        <p className='description first'>
          {
            'Why waste time learning and using many different\nsystems? Alpaca Guild brings them all together into\none easy to use system that you can trust and enjoy.'
          }
        </p>
        <p className='description second'>
          {
            'From the international registry and alpaca\nmanagement tools, through to the alpaca\nmarketplace and breeder directory,\nAlpaca Guild is fully integrated and easy to use.'
          }
        </p>
      </div>
      <div className='image-container browser-image'>
        <Image src='accessibility-browser-alpaca.png' alt='browser image' />
      </div>
      <div className='image-container mobile-image'>
        <Image src='accessibility-mobile-alpaca.png' alt='mobile image' />
      </div>
    </div>
  </Section>
);

export default Accessibility;
